<template>
  <LoadingScreenBaseLoader
    v-slot="{ isLoading, error }"
    :promises="promises"
    @loaded="clearFinishedPromises"
    @complete="$emit('complete')"
  >
    <div>
      <template v-if="(isLoading && !error) || show">
        <slot name="loadingscreen" />
      </template>
      <div
        v-if="error"
        class=""
      >
        {{ error }}
      </div>
      <slot
        v-if="!show"
        :is-loading="isLoading"
        :error="error"
        :load-promise="loadPromise"
      />
    </div>
  </LoadingScreenBaseLoader>
</template>

<script setup>
  defineEmits(['complete'])
  defineProps({
    show: {
      type: Boolean,
      default: false
    }
  })
  const promises = ref([])
  const loadPromise = (promise) => promises.value.push(promise)
  const clearFinishedPromises = () => promises.value.splice(0)
</script>
