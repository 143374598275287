<script>
  export default {
    name: 'BaseLoader',

    props: {
      promises: {
        type: Array,
        required: true
      }
    },

    emits: ['loaded', 'complete', 'error'],

    setup(props, { slots, emit }) {
      const isLoading = ref(false)
      const error = ref(null)

      watch(props.promises, async (promises) => {
        if (promises.length > 0) {
          isLoading.value = true
          try {
            await Promise.all(promises)
            emit('loaded')
          } catch (e) {
            error.value = e
            emit('error', e)
          } finally {
            isLoading.value = false
            emit('complete')
          }
        }
      })

      return () => slots.default({ isLoading: isLoading.value, error: error.value })
    }
  }
</script>
